/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-return-assign */

import Image from '@common_image';
import MagezonLink from '@core_modules/cms/components/cms-renderer/magezon/MagezonLink';
import useStyles from '@common_slick/Banner/style';
import { getStoreHost } from '@helpers/config';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import { breakPointsUp } from '@helper_theme';
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import dynamic from 'next/dynamic';

const MagezonButton = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonButton'));
const MagezonHeading = dynamic(() => import('@core_modules/cms/components/cms-renderer/magezon/MagezonHeading'));

const VideoContent = (props) => {
    const {
        background_type, youtube_id, vimeo_id, local_link,
    } = props;
    let videoUrl;

    if (background_type === 'youtube') videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
    if (background_type === 'vimeo') videoUrl = `https://player.vimeo.com/video/${vimeo_id}`;
    if (background_type === 'local') {
        return (
            <video>
                <source src={local_link} />
                <track kind="captions" />
                Sorry, your browser does not support embedded videos.
            </video>
        );
    }

    return (
        <>
            <iframe
                style={{ width: '100%', height: '100%' }}
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded Video"
            />
        </>
    );
};

const MagezonSliderContent = (props) => {
    const {
        heading,
        heading_animation,
        heading_bg_color,
        heading_color,
        heading_font_size,
        heading_line_height,
        heading_padding,
        heading_font_weight,
        heading_type,
        caption1,
        caption1_animation,
        caption1_bg_color,
        caption1_color,
        caption1_font_size,
        caption1_font_weight,
        caption1_line_height,
        caption1_padding,
        caption1_type,
        caption2,
        caption2_animation,
        caption2_bg_color,
        caption2_color,
        caption2_font_size,
        caption2_font_weight,
        caption2_line_height,
        caption2_padding,
        caption2_type,
        content_align,
        content_padding,
        content_position,
        content_width,
        content_wrapper_width,
        youtube_id,
        vimeo_id,
        local_link,
        image,
        background_type,
        slider_height,
        button1,
        button2,
        button1_bg_color,
        button2_bg_color,
        button1_color,
        button2_color,
        button1_link,
        button2_link,
        button1_size,
        button2_size,
        button1_border_style,
        button2_border_style,
        link_type,
        slide_link,
        storeConfig,
        lazy,
        // width,
        // height,
        preload,
    } = props;

    const mediaUrl = `${getStoreHost()}media`;
    const styles = useStyles();
    const desktop = breakPointsUp('sm');

    return (
        <>
            {background_type !== 'image' ? (
                <div style={{ height: `${slider_height}px` }}>
                    <VideoContent background_type={background_type} youtube_id={youtube_id} vimeo_id={vimeo_id} local_link={local_link} />
                </div>
            ) : (
                <div className="magezon-slide">
                    <div className={`magezon-slide-captions ${content_position}`}>
                        <div>
                            {heading && (
                                <div className="magezon-slide-heading" data-animate={heading_animation}>
                                    <MagezonHeading
                                        text={heading}
                                        heading_type={heading_type}
                                        font_size={heading_font_size}
                                        color={heading_color}
                                        font_weight={heading_font_weight}
                                    />
                                </div>
                            )}
                            {caption1 && (
                                <div className="magezon-slide-caption1" data-animate={caption1_animation}>
                                    <MagezonHeading
                                        text={caption1}
                                        heading_type={caption1_type}
                                        font_size={caption1_font_size}
                                        color={caption1_color}
                                        font_weight={caption1_font_weight}
                                    />
                                </div>
                            )}
                            {caption2 && (
                                <div className="magezon-slide-caption2" data-animate={caption2_animation}>
                                    <MagezonHeading
                                        text={caption2}
                                        heading_type={caption2_type}
                                        font_size={caption2_font_size}
                                        color={caption2_color}
                                        font_weight={caption2_font_weight}
                                    />
                                </div>
                            )}
                            {link_type === 'button' && (
                                <div className="magezon-slide-button">
                                    {button1 && (
                                        <MagezonButton
                                            link={button1_link}
                                            title={button1}
                                            button_size={button1_size}
                                            button_color={`${button1_color || '#333'}`}
                                            button_background_color={`${button1_bg_color || '#e3e3e3'}`}
                                            button_border_style={button1_border_style}
                                            button_align="center"
                                        />
                                    )}
                                    {button2 && (
                                        <MagezonButton
                                            link={button2_link}
                                            title={button2}
                                            button_size={button2_size}
                                            button_color={`${button2_color || '#333'}`}
                                            button_background_color={`${button2_bg_color || '#e3e3e3'}`}
                                            button_border_style={button2_border_style}
                                            button_align="center"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="magezon-slide-image">
                        {link_type === 'button' ? (
                            <Image
                                src={`${mediaUrl}/${image}`}
                                alt={heading || 'image-main-banner'}
                                width={desktop ? storeConfig?.pwa?.home_slider_desktop_width : storeConfig?.pwa?.home_slider_mobile_width}
                                height={desktop ? storeConfig?.pwa?.home_slider_desktop_height : storeConfig?.pwa?.home_slider_mobile_height}
                                useContainer={false}
                                storeConfig={storeConfig}
                                lazy={lazy}
                                className={styles.imageSliderBackground}
                                preload={preload}
                            />
                        ) : (
                            <MagezonLink link={slide_link}>
                                <Image
                                    src={`${mediaUrl}/${image}`}
                                    alt={heading || 'image-main-banner'}
                                    width={desktop ? storeConfig?.pwa?.home_slider_desktop_width : storeConfig?.pwa?.home_slider_mobile_width}
                                    height={desktop ? storeConfig?.pwa?.home_slider_desktop_height : storeConfig?.pwa?.home_slider_mobile_height}
                                    useContainer={false}
                                    storeConfig={storeConfig}
                                    lazy={lazy}
                                    className={styles.imageSliderBackground}
                                    preload={preload}
                                />
                            </MagezonLink>
                        )}
                    </div>
                </div>
            )}
            <style jsx>
                {`
                    .magezon-slide {
                        // height: ${slider_height}px;
                    }
                    .magezon-slide-image {
                        // height: ${slider_height}px;
                        overflow: hidden;
                        height: 39vw;
                        max-height: 532px;
                    }
                    .magezon-slide-image img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .magezon-slide-content-wrapper {
                        display: flex;
                        justify-content: flex-start;
                    }
                    .magezon-slide-captions {
                        max-width: ${content_wrapper_width}px;
                        display: flex;
                        margin: 0 auto;
                    }
                    .magezon-slide-captions > div {
                        max-width: ${content_width}px;
                        text-align: ${content_align};
                        padding: ${content_padding}px;
                    }
                    .magezon-slide-heading {
                        background-color: ${heading_bg_color};
                    }
                    .magezon-slide-caption1 {
                        background-color: ${caption1_bg_color};
                    }
                    .magezon-slide-caption2 {
                        background-color: ${caption2_bg_color};
                    }
                    .magezon-slide-heading,
                    .magezon-slide-caption1,
                    .magezon-slide-caption2 {
                        margin-bottom: 5px;
                    }
                    .magezon-slide-heading :global(.magezone-heading :is(h1, h2, h3, h4, h5, h6)) {
                        line-height: ${heading_line_height}px;
                        padding: ${heading_padding}px;
                        margin: 0;
                    }
                    .magezon-slide-caption1 :global(.magezone-heading :is(h1, h2, h3, h4, h5, h6)) {
                        line-height: ${caption1_line_height}px;
                        padding: ${caption1_padding}px;
                        margin: 0;
                    }
                    .magezon-slide-caption2 :global(.magezone-heading :is(h1, h2, h3, h4, h5, h6)) {
                        line-height: ${caption2_line_height}px;
                        padding: ${caption2_padding}px;
                        margin: 0;
                    }
                    .magezon-slide-captions :global(.magezone-heading) {
                        justify-content: center;
                    }
                    .magezon-slide-button {
                        display: flex;
                        gap: 10px;
                    }
                    .magezon-slide-captions.middle-center {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 10;
                    }
                    .middle-center {
                        align-items: center;
                        justify-content: center;
                    }
                    .middle-left {
                        align-items: center;
                        justify-content: flex-start;
                    }
                    .middle-right {
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .top-left {
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                    .top-center {
                        align-items: flex-start;
                        justify-content: center;
                    }
                    .top-right {
                        align-items: flex-start;
                        justify-content: flex-end;
                    }
                    .bottom-left {
                        align-items: flex-end;
                        justify-content: flex-start;
                    }
                    .bottom-center {
                        align-items: flex-end;
                        justify-content: center;
                    }
                    .bottom-right {
                        align-items: flex-end;
                        justify-content: flex-end;
                    }
                `}
            </style>
        </>
    );
};

const useHoverStyle = (hoverEffect) => {
    let unhoverStyle = '';
    let hoverStyle = '';
    if (hoverEffect === 'zoomin' || hoverEffect === 'zoomout') {
        unhoverStyle = 'transition: transform 1s, filter 2s ease-in-out; transform-origin: center; transform: scale(1);';
        hoverStyle = 'transition: transform 1s, filter 2s ease-in-out; transform-origin: center;';
    }
    if (hoverEffect === 'zoomin') hoverStyle += 'transform: scale(1.02);';
    if (hoverEffect === 'zoomout') hoverStyle += 'transform: scale(0.98);';

    return { unhoverStyle, hoverStyle };
};

const MagezonSlider = (props) => {
    const {
        items,
        image_hover_effect,
        content_position,
        owl_nav,
        owl_lazyLoad,
        owl_loop,
        owl_autoplay,
        owl_autoplay_timeout,
        owl_rtl,
        owl_nav_size,
        owl_nav_position,
        owl_animate_in,
        owl_animate_out,
        owl_active_background_color,
        owl_background_color,
        owl_color,
        owl_hover_background_color,
        owl_hover_color,
        owl_dots_insie,
        slider_height,
        storeConfig,
        el_id,
    } = props;

    const [, setSlideIndex] = useState(0);
    const { unhoverStyle, hoverStyle } = useHoverStyle(image_hover_effect);
    let slideHeight = storeConfig.pwa?.home_slider_desktop_height;
    let slideWidth = storeConfig.pwa?.home_slider_desktop_width;
    let eventSlideHeight = storeConfig.pwa?.magezon_slider_desktop_height;
    let eventSlideWidth = storeConfig.pwa?.magezon_slider_desktop_width;
    slideHeight = typeof slideHeight === 'string' ? parseInt(slideHeight, 0) : slideHeight;
    slideWidth = typeof slideWidth === 'string' ? parseInt(slideWidth, 0) : slideWidth;
    eventSlideHeight = typeof eventSlideHeight === 'string' ? parseInt(eventSlideHeight, 0) : eventSlideHeight;
    eventSlideWidth = typeof eventSlideWidth === 'string' ? parseInt(eventSlideWidth, 0) : eventSlideWidth;

    const navSize = owl_nav_size === 'mini' ? 10 : owl_nav_size === 'small' ? 15 : owl_nav_size === 'normal' ? 20 : 25;
    let sliderRef = useRef();

    const settings = {
        arrows: false,
        dots: true,
        infinite: owl_loop,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: owl_autoplay,
        autoplaySpeed: owl_autoplay_timeout || 2000,
        adaptiveHeight: true,
        pauseOnHover: true,
        lazyLoad: owl_lazyLoad,
        rtl: owl_rtl,
        customPaging: () => (
            <a rel="nofollow" href="?">
                <div className="custom-slick-dots" />
            </a>
        ),
        beforeChange: (oldIdx, newIdx) => {
            const containerEl = sliderRef.innerSlider.list.querySelector(`[data-index="${newIdx}"] > div > div`);
            const prevContainerEl = sliderRef.innerSlider.list.querySelector(`[data-index="${oldIdx}"] > div > div`);

            if (owl_animate_out) {
                prevContainerEl.classList.add('animate__animated', `animate__${owl_animate_out}`);
                containerEl.classList.add('animate__animated', `animate__${owl_animate_in}`);

                if (prevContainerEl.classList.contains(`animate__${owl_animate_in}`)) {
                    prevContainerEl.classList.remove('animate__animated', `animate__${owl_animate_in}`);
                    prevContainerEl.classList.add('animate__animated', `animate__${owl_animate_out}`);
                }

                if (containerEl.classList.contains(`animate__${owl_animate_out}`)) {
                    containerEl.classList.remove('animate__animated', `animate__${owl_animate_out}`);
                    containerEl.classList.add('animate__animated', `animate__${owl_animate_in}`);
                }
            }

            const el = sliderRef.innerSlider.list.querySelectorAll(`
                [data-index="${newIdx}"] .magezon-slide-heading,
                [data-index="${newIdx}"] .magezon-slide-caption1,
                [data-index="${newIdx}"] .magezon-slide-caption2
            `);
            const prevEl = sliderRef.innerSlider.list.querySelectorAll(`
                [data-index="${oldIdx}"] .magezon-slide-heading,
                [data-index="${oldIdx}"] .magezon-slide-caption1,
                [data-index="${oldIdx}"] .magezon-slide-caption2
            `);

            prevEl.forEach((element) => {
                element.classList.remove('animate__animated', `animate__${element.dataset.animate}`);
            });

            setTimeout(() => {
                el.forEach((element) => {
                    const animValue = element.dataset.animate;
                    if (animValue) {
                        element.classList.add('animate__animated', `animate__${element.dataset.animate}`);
                    }
                });
            }, 1000);
            setSlideIndex(newIdx);
        },
    };

    return (
        <>
            <div className="magezon-slider">
                {owl_nav && owl_nav_position.includes('top') && (
                    <div className="magezon-slider-nav-top-arrow">
                        <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickPrev()}>
                            <LeftArrowIcon />
                        </div>
                        <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickNext()}>
                            <RightArrowIcon />
                        </div>
                    </div>
                )}
                {owl_nav && owl_nav_position === 'center_split' && (
                    <div className="magezon-slider-nav-center-arrow">
                        <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickPrev()}>
                            <LeftArrowIcon />
                        </div>
                        <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickNext()}>
                            <RightArrowIcon />
                        </div>
                    </div>
                )}
                <div className="magezon-slider-inner">
                    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                        {items.map((item, i) => (
                            <MagezonSliderContent
                                key={i}
                                slider_height={slider_height}
                                content_position={content_position}
                                height={el_id === 'main-banner' ? slideHeight : eventSlideHeight}
                                width={el_id === 'main-banner' ? slideWidth : eventSlideWidth}
                                storeConfig={storeConfig}
                                {...item}
                                lazy={i !== 0}
                                preload={i === 0}
                            />
                        ))}
                    </Slider>
                </div>
                <div className="magezon-slider-nav-bottom">
                    {owl_nav && owl_nav_position.includes('bottom') && (
                        <div className="magezon-slider-nav-bottom-arrow">
                            <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickPrev()}>
                                <LeftArrowIcon />
                            </div>
                            <div className="magezon-slider-button-nav" onClick={() => sliderRef.slickNext()}>
                                <RightArrowIcon />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <style jsx>
                {`
                    .magezon-slider {
                        position: relative;
                    }
                    .magezon-slider-inner {
                        // height: ${slider_height}px;
                    }
                    .magezon-slider-inner :global(.slick-slide) {
                        height: auto;
                    }
                    .magezon-slider-inner :global(.slick-slide) > div {
                        background: yellow;
                    }
                    .magezon-slider-inner :global(.slick-track) {
                        // height: ${slider_height}px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: center;
                    }
                    .magezon-slider-inner :global(.slick-arrow:before) {
                        font-size: 20px;
                    }
                    .magezon-slider-inner :global(.slick-arrow) {
                        z-index: 99;
                        ${owl_nav ? '' : 'display: none !important;'}
                    }
                    .magezon-slider-inner :global(.slick-arrow.slick-prev) {
                        left: 12px;
                    }
                    .magezon-slider-inner :global(.slick-arrow.slick-next) {
                        right: 12px;
                    }
                    .magezon-slider-inner :global(.magezon-slide) {
                        text-align: center;
                        position: relative;
                        // padding-bottom: ${100 * (slideHeight / slideWidth)}%;
                        background-color: #ddd;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        ${unhoverStyle}
                    }
                    .magezon-slider-inner :global(.magezon-slide:hover) {
                        ${hoverStyle}
                    }
                    @media screen and (min-width: 768px) {
                        .magezon-slider-inner :global(.slick-arrow:before) {
                            font-size: 24px;
                        }
                        .magezon-slider-inner :global(.slick-arrow.slick-prev) {
                            left: 16px;
                        }
                        .magezon-slider-inner :global(.slick-arrow.slick-next) {
                            right: 16px;
                        }
                        .magezon-slider-inner :global(.slick-dots) {
                            bottom: -15px;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        .magezon-slider-inner :global(.slick-dots) {
                            bottom: -15px !important;
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .magezon-slider-nav-top-arrow {
                        display: flex;
                        justify-content: ${
        owl_nav_position === 'top_left' ? 'flex-start' : owl_nav_position === 'top_right' ? 'flex-end' : 'space-between'
        };
                    }
                    .magezon-slider-nav-center-arrow {
                        position: absolute;
                        display: flex;
                        width: 100%;
                        height: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        justify-content: ${
        owl_nav_position === 'top_left' ? 'flex-start' : owl_nav_position === 'top_right' ? 'flex-end' : 'space-between'
        };
                        z-index: 1;
                    }
                    .magezon-slider-nav-bottom-arrow {
                        display: flex;
                        justify-content: ${
        owl_nav_position === 'bottom_left'
            ? 'flex-start'
            : owl_nav_position === 'bottom_right'
                ? 'flex-end'
                : owl_nav_position === 'bottom_center'
                    ? 'center'
                    : 'space-between'
        };
                    }
                    .magezon-slider-button-nav {
                        position: relative;
                        background-color: ${owl_background_color || '#eeeeee66'};
                        width: ${navSize * 2}px;
                        height: ${navSize * 2}px;
                        margin: 0 4px;
                        bottom: ${navSize}px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                    }
                    .magezon-slider-button-nav :global(svg) {
                        font-size: 25px;
                        color: ${owl_color};
                    }
                    .magezon-slider-button-nav:hover {
                        background-color: ${owl_hover_background_color || '#eee'};
                    }
                    .magezon-slider-button-nav:hover :global(svg) {
                        ${owl_hover_color && `color: ${owl_hover_color};`}
                    }
                    .magezon-slider-button-nav:hover {
                        cursor: pointer;
                        border: 1px solid black;
                    }
                    .magezon-slider-nav-bottom {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .magezon-slider-inner :global(.slick-dots) {
                        position: relative;
                        ${owl_dots_insie ? 'bottom: 50px;' : ''}
                    }
                    .magezon-slider-inner :global(.custom-slick-dots) {
                        width: 10px;
                        height: 10px;
                        background-color: ${owl_background_color || '#eee'};
                        border-radius: 50px;
                        transition: transform 0.5s;
                    }
                    @media screen and (max-width: 767px) {
                        .magezon-slider-inner :global(.custom-slick-dots) {
                            width: 7px;
                            height: 7px;
                        }
                        .magezon-slider-inner :global(.slick-dots) {
                            position: relative;
                            bottom: -10px;
                            
                            ${owl_dots_insie ? 'bottom: 30px;' : ''}
                        }
                    }
                    .magezon-slider-inner :global(.custom-slick-dots) {
                        background-color: ${owl_active_background_color || 'transparent'};
                        width: 10px;
                        height: 10px;
                        border: 2px solid #000;
                    }
                    .magezon-slider-inner :global(.slick-active .custom-slick-dots) {
                        background-color: ${owl_active_background_color || '#000'};
                        width: 10px;
                        height: 10px;
                        // ${owl_dots_insie ? 'transform: scale(1.5);' : ''}
                    }
                    .magezon-slider-inner :global(.slick-slider li:not(.slick-active) .custom-slick-dots:hover) {
                        background-color: ${owl_hover_background_color};
                    }
                `}
            </style>
        </>
    );
};

export default MagezonSlider;
