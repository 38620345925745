import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as Schema from '@core_modules/cms/services/graphql/schema';
import { getLoginInfo } from '@helper_auth';

let isLogin = 0;
if (typeof window !== 'undefined') {
    isLogin = getLoginInfo();
}

export const getCategoriesInfo = (options) => useQuery(Schema.getCategoriesInfo, options);
export const getCmsPage = (variables) =>
    useQuery(Schema.getCmsPage, {
        variables,
        context: {
            request: isLogin ? 'internal' : '',
        },
        ssr: true,
        ...(isLogin && { fetchPolicy: 'network-only' }),
    });
export const getInstagramToken = () => useLazyQuery(Schema.getInstagramToken);

export const getPageBuilderTemplate = (variables) =>
    useQuery(Schema.getPageBuilderTemplate, {
        variables,
    });

// mutation
export const getInstagramFeed = () =>
    useMutation(Schema.getInstagramFeed, {
        context: {
            request: 'internal',
        },
    });

export const getCmsBlocks = (variables) =>
    useQuery(Schema.getCmsBlocks, {
        variables,
        context: {
            request: isLogin ? 'internal' : '',
        },
        fetchPolicy: isLogin ? 'network-only' : '',
    });

export const getProductReviews = (variables) => useQuery(Schema.getProductReviews, { variables });
export const getProductList = (config = {}) => useLazyQuery(Schema.getProductList(config));
export const getProductPrice = (variables) =>
    useLazyQuery(Schema.getProductPrice, {
        variables,
        context: {
            request: isLogin ? 'internal' : '',
        },
        fetchPolicy: 'no-cache',
    });
export const getCategories = (variables) => useQuery(Schema.getCategories, { variables });
export const getMpDailyDeals = (variables) => useQuery(Schema.getMpDailyDeals, {
    variables,
});

export default { getCmsPage };
