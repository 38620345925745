/* eslint-disable no-nested-ternary */

import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        borderStyle: (props) => props.border_style || 'solid',
        backgroundPosition: (props) => props.background_position,
        backgroundColor: (props) => props.background_color || 'transparent',
        borderColor: (props) => props.border_color || 'transparent',
        borderWidth: (props) =>
            `${props.border_top_width || 0}px ${props.border_right_width || 0}px ${props.border_bottom_width || 0}px ${
                props.border_left_width || 0
            }px` || '0 0 0 0',
        margin: (props) =>
            `${props.margin_top || 0}px ${props.margin_right || 0}px ${props.margin_bottom || 0}px ${props.margin_left || 0}px ` || '0 0 0 0',
        padding: (props) =>
            `${props.padding_top || 0}px ${props.padding_right || 0}px ${props.padding_bottom || 0}px ${props.padding_left || 0}px` || '0 0 0 0',
        '&.mgz-element-row-max-width': {
            height: '100%',
            [theme.breakpoints.up('md')]: {
                padding: '0 80px',
            },
        },
        '& .mgz-product, .mgz-product-content, .mgz-product-slider-content, .slick-slider': {
            height: 'inherit',
        },
        '& .slick-list': {
            height: 'inherit !important',
        },
        '& .mgz-product-slider': {
            height: 'inherit',
            '& .slick-list': {
                height: 'auto',
                '& .slick-slide > div': {
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '100%',
                    '& .mgz-single-product-card': {
                        padding: '14px !important',
                        [theme.breakpoints.down('sm')]: {
                            padding: '10px !important',
                        },
                        '& .MuiGrid-container': {
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            '& a > span': {
                                paddingTop: '100% !important',
                                '& img': {
                                    backgroundColor: WHITE,
                                },
                            },
                            '& h4': {
                                margin: '12px 0 0',
                                textTransform: 'capitalize',
                                display: 'inline-block',
                                fontSize: 13,
                                height: 38,
                                lineHeight: '19px',
                                display: '-webkit-box',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': 2,
                                overflow: 'hidden',
                            },
                        },
                        '& .MuiGrid-item': {
                            width: '100%',
                            '& button': {
                                padding: 0,
                                '& span': {
                                    padding: 0,
                                },
                            },
                        },
                    },
                },
            },
        },
        '& .mgz-element.brand-logo': {
            '& picture': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .mgz-element-inner': {
                padding: 10,
                '&:hover': {
                    border: '1px solid',
                    borderRadius: 10,
                    overflow: 'hidden',
                    padding: 9,
                }
            }
        },
        '& #category': {
            backgroundColor: 'blue',
            '& .category-icon': {
                padding: 20,
            },
        },
    },
    button: (props) => ({
        transition: 'all 0.2s ease-in-out',
        backgroundPosition: props.background_position,
        color: props.button_color || '#000000',
        fontSize:
            props.button_size === 'xs'
                ? '11px'
                : props.button_size === 'sm'
                ? '12px'
                : props.button_size === 'md'
                ? '14px'
                : props.button_size === 'lg'
                ? '17px'
                : props.button_size === 'xl'
                ? '21px'
                : '12px',
        padding:
            props.button_size === 'xs'
                ? '8px 12px'
                : props.button_size === 'sm'
                ? '11px 16px'
                : props.button_size === 'md'
                ? '15px 21px'
                : props.button_size === 'lg'
                ? '20px 27px'
                : props.button_size === 'xl'
                ? '26px 34px'
                : '11px 16px',
        boxShadow: props.button_style === '3d' ? `0 5px 0 ${props.box_shadow_color}` : 'none',
        borderStyle: props.button_border_style,
        borderWidth: props.button_border_width ? `${props.button_border_width}px` : 0,
        borderRadius: props.button_border_radius ? `${props.button_border_radius}px` : '5px',
        borderColor: props.button_border_color || 'transparent',
        backgroundColor: props.button_background_color || '#e3e3e3',
        ...(props.full_width && { width: '100%' }),
        ...(props.button_style === 'gradient' &&
            (props.gradient_color_1 || props.gradient_color_2) && {
                backgroundImage: `
                linear-gradient(to right, ${props.gradient_color_1} 0, ${props.gradient_color_2} 50%, ${props.gradient_color_1} 100%)
            `,
                backgroundSize: '200% 100%',
            }),
        '& .magezon-icon': {
            margin:
                props.button_size === 'xs'
                    ? '0px 4px'
                    : props.button_size === 'sm'
                    ? '0px 6px'
                    : props.button_size === 'md'
                    ? '0px 9px'
                    : props.button_size === 'lg'
                    ? '0px 13px'
                    : props.button_size === 'xl'
                    ? '0px 15px'
                    : '0px 6px',
        },
        '&:hover': {
            color: props.button_hover_color
                ? `${props.button_hover_color} !important`
                : props.button_color
                ? `${props.button_color} !important`
                : '#333333',
            backgroundColor: props.button_hover_background_color
                ? `${props.button_hover_background_color} !important`
                : props.button_background_color
                ? `${props.button_background_color} !important`
                : '#e3e3e3',
            borderColor: props.button_hover_border_color || 'transparent',
            ...(props.button_style === 'gradient' &&
                (props.gradient_color_1 || props.gradient_color_2) && {
                    backgroundPosition: '100% 0',
                }),
            '& .magezon-icon': {
                '& i': {
                    color: props.button_hover_color || '#333333',
                },
            },
        },
    }),
    mgzMessageBox: {
        '& > .magezone-icon .wrapperIcon': {
            fontSize: '1em',
            fontStyle: 'normal',
            fontWeight: 400,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: '3.6em',
            marginLeft: 0,
            marginRight: 0,
        },
        '& > .magezone-icon .wrapperIcon i': {
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            lineHeight: 1,
            fontSize: (props) => props.icon_size,
        },
        '& > .magezone-icon .wrapperIcon, .magezone-icon .wrapperIcon i': {
            color: (props) => props.message_icon_color,
            backgroundColor: (props) => props.message_icon_background_color,
        },
    },
    wrapper: {
        '&.xs-hide': {
            '@media (max-width: 575px)': {
                display: 'none !important',
            },
        },
        '&.sm-hide': {
            '@media (min-width: 576px) and (max-width: 767px)': {
                display: 'none !important',
            },
        },
        '&.md-hide': {
            '@media (min-width: 768px) and (max-width: 991px)': {
                display: 'none !important',
            },
        },
        '&.lg-hide': {
            '@media (min-width: 992px) and (max-width: 1200px)': {
                display: 'none !important',
            },
        },
        '&.xl-hide': {
            '@media (min-width: 1200px)': {
                display: 'none !important',
            },
        },
    },
}));

export default useStyles;
